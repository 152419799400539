<template>
  <form action data-vv-scope="invoices-form" @submit.prevent="save">
    <div class="close">
      <v-btn icon @click="$emit('close')" small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12" lg="6">
        <UserSelector :invoice="invoice" ref="from" />
      </v-col>
      <v-col cols="12" lg="6">
        <UserSelector :invoice="invoice" type="to" ref="to" />
      </v-col>

      <v-col cols="12">
        <v-card elevation="6" class="my-1">
          <v-simple-table id="items">
            <tbody>
              <tr>
                <th
                  v-if="selectable"
                  class="font-weight-light text-uppercase"
                  width="10"
                ></th>
                <th class="font-weight-light text-uppercase" width="500">
                  {{ $t("invoices.items.description") }}
                </th>
                <th class="font-weight-light text-uppercase" width="100">
                  {{ $t("invoices.items.quantity") }}
                </th>
                <th class="font-weight-light text-uppercase" width="100">
                  {{ $t("invoices.items.price") }}
                </th>
                <th class="font-weight-light text-uppercase" width="100">
                  {{ $t("invoices.items.total") }}
                </th>
                <th class="font-weight-light text-uppercase" width="10"></th>
              </tr>
              <tr v-for="(item, i) in invoice.items" :key="i">
                <td v-if="selectable">
                  <v-checkbox
                    v-if="item.reference_id"
                    v-model="item.selected"
                    hide-details
                    on-icon="mdi-check-circle"
                    off-icon="mdi-circle-outline"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="item.description"
                    data-vv-validate-on="blur"
                    hide-details
                    dense
                    v-validate="'required'"
                    outlined
                    :error-messages="
                      errors.collect('invoices-form.description-' + i)
                    "
                    :data-vv-name="'description-' + i"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="item.quantity"
                    outlined
                    data-vv-validate-on="blur"
                    dense
                    v-validate="'required'"
                    hide-details
                    :error-messages="
                      errors.collect('invoices-form.quantity-' + i)
                    "
                    :data-vv-name="'quantity-' + i"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="item.price"
                    data-vv-validate-on="blur"
                    dense
                    outlined
                    hide-details
                    v-validate="'required'"
                    :error-messages="errors.collect('invoices-form.price-' + i)"
                    :data-vv-name="'price-' + i"
                  ></v-text-field>
                </td>
                <td>
                  {{
                    item.quantity && item.price
                      ? $n(item.quantity * item.price, "currency")
                      : "-"
                  }}
                </td>
                <td>
                  <v-btn icon @click="delItem(i)" v-if="!item.reference_id">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td :colspan="selectable ? 6 : 5">
                  <v-btn icon @click="addItem">
                    <v-icon size="36">mdi-plus-circle</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <Taxes :invoice="invoice" :base="invoiceBase" ref="taxes" />
      </v-col>

      <v-col align="center" cols="12" md="4">
        <v-card height="100%" class="my-1">
          <v-card-title class="text-uppercase d-flex justify-center">
            {{ $t("invoices.final") }}
          </v-card-title>
          <v-card-text style="font-size:40px">
            {{ $n(priceFinal, "currency") }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="my-2">
      <v-col align="end">
        <v-btn height="30px" outlined @click="$emit('cancel')" class="mx-2">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn type="submit" elevation="0" height="30px">
          <v-icon size="14px" class="mr-1">$save</v-icon>
          {{ $t("save", { name: "" }) }}
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "InvoicesForm",
  props: {
    invoice: {
      type: Object,
      default: () => ({ items: [], taxes: [] }),
    },
    selectable: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    invoiceBase() {
      let total = 0;
      this.invoice.items.forEach((item) => {
        if (item.price && item.quantity) total += item.price * item.quantity;
      });
      return total;
    },
    priceFinal() {
      let total = this.invoiceBase;
      this.invoice.taxes.forEach((t) => {
        if (!t.included) total += (this.invoiceBase * t.percentage) / 100;
      });
      return total;
    },
  },
  components: {
    UserSelector: () => import("./UserSelector"),
    Taxes: () => import("./InvoicesTaxes"),
  },
  methods: {
    ...mapActions("invoices", [""]),
    addItem() {
      this.invoice.items.push({ selected: true });
    },
    delItem(index) {
      this.invoice.items.splice(index, 1);
    },
    async save() {
      //console.log("=====================");
      //console.log("| V A L I D A M O S |");
      //console.log("=====================");
      //console.log("");
      let from = await this.$refs.from.validate();
      //console.log("from", from);
      let to = await this.$refs.to.validate();
      //console.log("to", to);
      let taxes = true;
      //console.log("taxes", taxes);
      let items =
        this.invoice.items.length > 0 &&
        (await this.$validator.validateAll("invoices-form"));
      //console.log("items", items);
      //console.log("");
      if (from && to && taxes && items) this.$emit("validated");
      else if (this.invoice.items.length === 0) {
        this.$alert("La factura debe tener almenos un concepto");
      }
    },
  },
};
</script>
<style lang="sass" scoped>
#items
  th
    color: var(--v-primary-base) !important
    text-transform: uppercase
    font-size: 16px
</style>
